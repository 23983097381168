import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"

import { pagesLinks } from "../../common/links"

import implementationStyles from "../common/implementation-feature/styles.module.scss";
import indicatorsStyles from "../common/indicators-feature/styles.module.scss";
import loyaltyStyles from "../common/loyalty-feature/styles.module.scss";

export default [
	{
		title: "Программа автоматизации для кондитерской в облаке",
		description: (
			<>
				<p>
					Управлять продажами в&nbsp;кондитерской и&nbsp;отслеживать финансовые
					результаты теперь не&nbsp;просто удобно, с&nbsp;Quick Resto это ещё
					и&nbsp;современно, и&nbsp;технологично.
				</p>
				<p>
					Единая экосистема объединяет в&nbsp;себе интуитивно понятный кассовый
					терминал на&nbsp;планшете, мобильное приложение с&nbsp;функциями
					предзаказа и&nbsp;push-уведомлений, личный кабинет с&nbsp;полной
					статистикой продаж и&nbsp;аналитикой за&nbsp;прошедшие периоды
					и&nbsp;отслеживание складских остатков готовых кондитерских изделий.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/konditerskaya-feature-1.png"
				alt="программа автоматизации кондитерской"
				breakpoints={[440, 740, 920, 630]}
				sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 630px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		reverse: true,
		className: styles.feature1,
		theme: "white",
	},
	{
		title: "Весовой товар и продажа частями",
		description: (
			<p className={styles.smallDescription}>
				В&nbsp;Quick Resto есть возможность подключения электронных весов для
				продажи кондитерских изделий по&nbsp;частям. Программа автоматически
				рассчитывает стоимость в&nbsp;зависимости от&nbsp;веса или может поделить
				целое блюдо на&nbsp;равные части. Чтобы считать вручную, нужно время,
				а&nbsp;ещё сотрудники часто ошибаются. Поэтому системы учёта с&nbsp;электронными
				весами так популярны для автоматизации кондитерских.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/konditerskaya-feature-2.png"
				alt="продажа блюд частями"
				breakpoints={[380, 660, 840, 620]}
				sizes="(max-width: 500px) 380px, (max-width: 800px) 660px, (max-width: 1319px) 840px, (min-width: 1320px) 620px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		actionText: "Подробнее",
		actionLink: pagesLinks.terminal.href,
		className: styles.feature2,
		theme: "white",
	},
	{
		title: "Техкарты и модификаторы",
		description: (
			<>
				<p>
					Для корректного складского учёта в&nbsp;кондитерских в&nbsp;Quick Resto
					используются технологические карты. Этот документ&nbsp;&mdash; основание
					для расчёта расхода продуктов, он&nbsp;фактически определяет затраты
					предприятия на&nbsp;производство.
				</p>
				<p>
					Модификаторы позволяют менять свойства блюд, с&nbsp;их&nbsp;помощью
					можно &laquo;уточнять&raquo; способ приготовления или подачи. Добавили
					гостю ванильный сироп&nbsp;&mdash; выбрали нужный модификатор. Модификаторы
					в&nbsp;системе автоматизации кондитерской&nbsp;&mdash; инструмент для
					увеличения выручки. С&nbsp;ними сотрудники не&nbsp;забывают предлагать
					дополнения к&nbsp;блюду.
				</p>
				<p>
					Ещё в&nbsp;программе для кондитерского производства Quick Resto
					предусмотрен учёт полуфабрикатов, чтобы корректно отражать процесс
					приготовления.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/konditerskaya-feature-3.png"
				alt=" программа для кондитерской"
				breakpoints={[440, 740, 920, 470]}
				sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 470px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		actionText: "Подробнее",
		actionLink: pagesLinks.menu.href,
		reverse: true,
		className: styles.feature3,
		theme: "white",
	},
	{
		title: "Себестоимость и калькуляция",
		description: (
			<p>
				В&nbsp;кондитерской множество десертов, а&nbsp;у&nbsp;каждого свои ингредиенты,
				типы обработки, потери. Нужны точные и&nbsp;актуальные данные, чтобы
				оптимизировать процессы и&nbsp;обеспечить стабильно высокую прибыль.
				С&nbsp;корректным учётом расхода продуктов, объёма потерь и&nbsp;себестоимости
				поможет система автоматизации кондитерского производства Quick Resto.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image4}
				src="./assets/konditerskaya-feature-4.png"
				alt="расчет себестоимости блюд"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		actionText: "Подробнее",
		actionLink: pagesLinks.menu.href,
		className: styles.feature4,
		theme: "white",
	},
	{
		title: "Терминал, продуманный до мелочей",
		description: (
			<>
				<p>
					На&nbsp;кассовом терминале простая навигация и&nbsp;подсказки для
					кассиров. А&nbsp;для вас&nbsp;&mdash; возможность планировать продажи.
				</p>
				<p>
					Программа позволяет контролировать актуальность меню. Сделайте
					на&nbsp;терминале папку с&nbsp;новинками или высокомаржинальными
					блюдами. Персонал в&nbsp;начале смены проверит план и&nbsp;будет
					предлагать гостям&nbsp;то, что вам более выгодно продать. Так
					вы&nbsp;сможете снизить потери продуктов и&nbsp;увеличить
					эффективность работы.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image5}
				src="./assets/konditerskaya-feature-5.png"
				alt="кассовое оборудование для кондитерской"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		actionText: "Подробнее",
		actionLink: pagesLinks.terminal.href,
		reverse: true,
		className: styles.feature5,
		theme: "white",
	},
	{
		title: "Удобная CRM система для кондитерской",
		description: (
			<p className={styles.smallDescription}>
				Расширяй возможности бизнеса с&nbsp;дополнительными приложениями
				и&nbsp;оборудованием: подключи тикет-принтер для кухни, дисплей повара
				и&nbsp;дисплей покупателя, а&nbsp;также экран электронной очереди.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image6}
				src="./assets/konditerskaya-feature-6.png"
				alt="crm для кондитерской"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
		className: styles.feature6,
		theme: "white",
	},
	{
		title: "Онлайн система управления кондитерской",
		description: (
			<p>
				Система автоматизации кондитерского производства Quick Resto позволяет
				отслеживать и&nbsp;анализировать все процессы работы: от&nbsp;приёмки
				товаров на&nbsp;точке продаж до&nbsp;определения самых продаваемых
				позиций и&nbsp;расчёта зарплат сотрудников.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image7}
				src="./assets/konditerskaya-feature-7.png"
				alt="система автоматизации кондитерской"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		className: styles.feature7,
		theme: "white",
	},
	{
		title: "Программа лояльности, как инструмент для привлечения гостей",
		description: (
			<p>
				Quick Resto поддержит акции, бонусы, абонементы, купоны, спецпредложения
				и другие программы лояльности.
				<br />
				Мы бесплатно разработаем мобильное приложение для кондитерской, и ты
				сможешь предложить гостям доставку блюд и&nbsp;предзаказ с
				онлайн-оплатой.
			</p>
		),

		img: (
			<StaticImage
				className={loyaltyStyles.image}
				src="../common/loyalty-feature/loyalty-feature.png"
				alt="программы лояльности для кондитерской"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,

		className: loyaltyStyles.feature,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Показатели всегда под рукой",
		description: (
			<p className={styles.smallDescription}>
				Используй мобильное приложение Quick Resto Manager для быстрого
				удаленного доступа к статистике работы заведения. Сможешь оценить
				эффективность программ лояльности, работу сотрудников, прибыльность блюд
				и многое другое.
			</p>
		),

		img: (
			<StaticImage
				className={indicatorsStyles.image}
				src="../common/indicators-feature/indicators-feature.png"
				alt="quick resto manager"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: indicatorsStyles.feature,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.indexQRM.href,
	},
	{
		title: "Внедрение системы автоматизации кондитерской под ключ",
		description: (
			<>
				<p>От первой продажи до масштабирования.</p>
				<p>Quick Resto даст все инструменты:</p>
				<ul>
					<li>Приложения для управления и продаж</li>
					<li>Отчёты по продажам, блюдам, сотрудникам</li>
					<li>Складской учёт и технологические карты</li>
					<li>CRM-система и аналитика</li>
					<li>Сервис: установка, настройка, обучение</li>
					<li>Круглосуточная техподдержка</li>
				</ul>
			</>
		),

		img: (
			<StaticImage
				className={implementationStyles.image}
				src="../common/implementation-feature/implementation-feature.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: implementationStyles.feature,
		theme: "white",
		backdrop: (
			<div className={implementationStyles.featureTopRightTrapezeBackdrop} />
		),
	},
]
