import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 545,
			tabName: "Склад",
			desktopTitle: "Складские операции на кассовом терминале",
			desktopContent:
				"В программе учёта Quick Resto можно проводить складские операции прямо с кассового терминала: доверь персоналу создание приходных накладных и проведение инвентаризаций. Все результаты будут доступны в облачном бэк-офисе с любого устройства.",
			detailLink: pagesLinks.warehouse,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/konditerskaya-accounting-1.png"
					alt="складской учет в кондитерской"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 545,
			tabName: "Отчеты",
			desktopTitle: "Увеличивай средний чек и свою прибыль",
			desktopContent:
				"Отчеты по продажам покажут, какие блюда приносят больше прибыли бизнесу. Отчеты по сотрудникам дадут возможность увидеть, кто продаёт лучше. Автоматический расчет зарплат добавит прозрачности и мотивации для вашего персонала.",
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/konditerskaya-accounting-2.png"
					alt="abc-анализ"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
