import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Для гостей",
			desktopTitle: "Экран покупателя",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Когда расчет происходит непосредственно на кассе в момент заказа,
					экран покупателя выступает наиболее простым средством контроля со
					стороны гостя. Он показывает какие позиции и по какой цене добавляются
					в чек, тем самым повышает доверие и снижает вероятность возникновения
					недопонимания. В режиме «свободной кассы», экран покупателя может
					работать как дополнительная рекламная площадь, транслируя необходимую
					информацию.
				</p>
			),
			detailLink: pagesLinks.customerDisplay,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/konditerskaya-modes-1.png"
					alt="экран покупателя в кондитерской"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Режимы",
			desktopTitle: "Режим работы Quick Food",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Интерфейс кассового терминала для автоматизации кондитерского производства
					и подобных форматов, режим работы “заказ-чек”.
					Никаких лишних действий, все ради ускорения процесса обслуживания.
					Режим Quick Food действительно способен разгрузить очереди — от выбора
					блюда до приёма оплаты всего пара кликов.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/konditerskaya-modes-2.png"
					alt="кассовый терминал в кондитерской"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Типы оплат",
			desktopTitle: "Приём различных типов оплат",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Оплата наличными или картой в несколько действий и последующий
					корректный учёт это именно то, чего так не хватало малым форматам
					бизнеса и это то, что предлагает программа Quick Resto.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/konditerskaya-modes-3.png"
					alt="кассовая система для кондитерской"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
